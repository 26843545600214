import { Injectable, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ShowMesageService {

  @Input() message = '';
  constructor(
    private _toastr: ToastrService
  ) { }

  successMessage(){
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `${this.message}`,
      showConfirmButton: false,
      timer: 3000,
    })
  }

  errorMessage(){
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    })
  }


  // toast success message
  toastSuccess(message){
    this._toastr.success(message, 'Success!', { progressBar: true });
  }

  // toast warning message
  toastWarning(message : string){
    this._toastr.warning(message, '',{ progressBar: true });
  }

  // toast info message
  toastInfo(message : string){
    this._toastr.info(message,'',{ progressBar: true });
  }

  // toast error message
  toastError(message : string){
    this._toastr.error(message, 'Opps!',{ progressBar: true });
  }


}
