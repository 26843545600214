import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { EncryptDecryptService } from '../encryptDecrypt/encrypt-decrypt.service';
import {RolesAccess} from 'src/app/shared/constants/roleAccess'

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  userMenu: any = [];

  constructor(
    private _http: HttpClient,
    private _encryptDecryptService: EncryptDecryptService,
  ) { }

  get_route_list_of_user = `${environment.base_url}/role/user-menu`
  getMenuList(getBody) {
    return this._http.post<any>(this.get_route_list_of_user, getBody);
  }

  hasRoleBypath(route): boolean {
    const eText = JSON.parse(localStorage.getItem('userRouteList'))
    const decryptedWord = CryptoJS.AES.decrypt(eText, 'route_list')
    const userMenu = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));

    // const userMenu = JSON.parse(localStorage.getItem('userRouteList'))
    let routeName = route.substring(11)



    if (userMenu) {
      const value = userMenu.filter((x) => x.angular_route == routeName);
      if (value.length < 1) {
        return false
      } else {
        return true
      }
    }
    return false;
  }


  hasRole() {
    const data = this._encryptDecryptService.decryptToken()
    const roleName = data['type']
    return roleName
  }
}
