import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/_modules/rbac/role/service/role.service';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  routerName: any = "";
  routeDataList: any = [];
  userID: any;
  isRoleLoading: boolean = false;

  constructor(
    private _roleService: RoleService,
    private _router: Router,
    private _encryptDecrypt: EncryptDecryptService,
    private _authService: AuthenticationService
  ) { }

  ngOnInit() {
    if ((this._authService.isLoggedIn) == false) {
      this._authService.logout();
    }

    // if ((this._authService.isMenuExist) == false) {
    //   this._authService.logout();
    // }



  }

}
