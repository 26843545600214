import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { config } from "src/app/config";
import { BehaviorSubject, Observable, Subscription, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "src/app/shared/models/User";
import { EncryptDecryptService } from "src/app/shared/services/encryptDecrypt/encrypt-decrypt.service";
import { Router } from "@angular/router";
import { delay } from "rxjs/operators";
import { Role } from "src/app/shared/models/Role";
import { ShowMesageService } from "src/app/shared/services/showMessage/show-message.service";
import { RoleService } from "../roleService/role.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public currentUser: Observable<User>;
  public currentUserSubject: BehaviorSubject<User>;
  login_api_url = `${config.base_url}/user/login`;
  tokenSubscription = new Subscription();
  timeout;
  user;
  routerName;
  role = Role;

  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService,
    private _router: Router,
    private _showMessage: ShowMesageService,
    private _roleService: RoleService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // getter aaccesstoken
  public get getAccessToken() {
    const accessToken = this._encryptDecrypt.decryptToken();

    if (accessToken != null) {
      return accessToken["accesstoken"];
    }
  }

  // getter refreshtoken
  public get getRefreshToken() {
    const refreshToken = this._encryptDecrypt.decryptToken();
    if (refreshToken != null) {
      return refreshToken["refreshtoken"];
    }
  }

  // return roles
  getRoles() {
    const data = this._encryptDecrypt.decryptToken();
    if (data != null) {
      console.log("This user's role is => ", data["type"]);
      return data["type"];
    }
  }

  //getter role
  public get getRole() {
    const data = this._encryptDecrypt.decryptToken();
    if (data != null) {
      return data["type"].toLocaleLowerCase();
    }
  }

  // getter isadmin
  get isAdmin() {
    return this.isLoggedIn && this.getRole === Role.ADMIN;
  }

  //getter isagent
  get isAgent() {
    return this.isLoggedIn && this.getRole === Role.AGENT;
  }

  //getter iscustomer
  get isCustomer() {
    return this.isLoggedIn && this.getRole === Role.CUSTOMER;
  }

  //getter isdoctor
  get isDoctor() {
    return this.isLoggedIn && this.getRole === Role.DOCTOR;
  }

  //getter islaboratory
  get isLaboratory() {
    return this.isLoggedIn && this.getRole === Role.LAB;
  }

  //getter isagency
  get isAgency() {
    return this.isLoggedIn && this.getRole === Role.AGENCY;
  }

  //getter isHr
  get isHr() {
    return this.isLoggedIn && this.getRole === Role.HR;
  }

  // logged in confirm
  get isLoggedIn() {
    if (localStorage.getItem("currentUser")) {
      return true;
    } else {
      return false;
    }
  }

  // if menu exist in localstorage
  get isMenuExist() {
    if (localStorage.getItem("userRouteList")) {
      return true;
    } else {
      return false;
    }
  }

  // user login
  login(username: string, password: string) {
    return this._http
      .post<any>(this.login_api_url, { username, password })
      .pipe(
        map((user) => {
          // when user credential is not match
          if (user["statusCode"] == "403") {
            this._showMessage.toastWarning(user["message"]);
          } else {
            // login successful if there's a jwt token in the response
            if (user && user.data.accesstoken) {
              localStorage.setItem("user_code", user.data.accswift_code);
              localStorage.setItem("type", user.data.type);
              localStorage.setItem(
                "accswift_accesToken",
                JSON.stringify(user.data.accswift_accesstoken.access_token)
              );
              this._encryptDecrypt.encryptToken(user);

              // notify
              this.user = this.getUser(user.data);
              this.currentUserSubject.next(user);
            }
          }

          return user;
        })
      );
  }

  // get user
  private getUser(data): User {
    return data as User;
  }

  //   getUserData(){
  // return this.getUser(data)
  //   }

  expirationCounter(timeout) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
      .pipe(delay(timeout))
      .subscribe((expired) => {});
  }

  // user lgout
  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userRouteList");
    // notify
    this.currentUserSubject.next(null);
    this._router.navigate(["/"]);
  }

  // get route list
  getRouteListOfUser(userId) {
    this._roleService.getMenuList(userId).subscribe((res) => {
      console.log("RESponse is => ", res["data"]);
      if (res["status"] == true) {
        this._encryptDecrypt.setMenu(res["data"]);
      }
    });
  }
}
