import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';
// import { EncryptDecryptService } from 'src/app/shared/services/encyptDecrypt/encrypt-decrypt.service';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _encryptService : EncryptDecryptService,
    ) { }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const currentUser = this._authenticationService.currentUserValue

        const current_user = this._encryptService.decryptToken()
        // return true;


        if (current_user) {
            // check if route is restricted by role

            if (route.data.roles && route.data.roles.indexOf(current_user.type) === -1) {

                // console.log("Hello there...")

                if (localStorage.getItem('currentUser')) {

                    // const eText = JSON.parse(localStorage.getItem('currentUser')).toString()
                    // const decryptedWord = CryptoJS.AES.decrypt(eText, 'secret_key')
                    // const decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));

                    const eText = JSON.parse(localStorage.getItem('currentUser'))
                    const decryptedWord = CryptoJS.AES.decrypt(eText, 'my_token')
                    const decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));

                    // console.log("decrypted data = ", decryptedData)

                    if (route.data.roles && route.data.roles.indexOf(decryptedData.role) === -1) {
                        this._router.navigate(['not-authorized'])
                        return false


                        // this._router.navigate(['not-authorized'])
                        // return false
                    }

                } else {
                    this._router.navigate(['not-authorized'])
                    return false
                }
            }

            // authorized so return true
            return true
        }

        // not logged in so redirect to login page with the return url
        this._router.navigate(['not-authorized'], { queryParams: { returnUrl: state.url } })
        return false
    }

}
