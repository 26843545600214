import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';
import { RoleService } from 'src/app/_modules/rbac/role/service/role.service';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';
declare var $: any;
import { navItems } from '../routList/nav';
import { Role } from 'src/app/shared/models/Role'
import $ from 'jquery';


@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {

  // variable declaration
  // @Input() navListData = [];
  isAdminUser: boolean = false;
  isAgentUser: boolean = false;
  isDoctorUser: boolean = false;
  isLaboratoryUser: boolean = false;
  isCustomerUser: boolean = false;
  isHRUser: boolean = false;
  isAgencyUser: boolean = false;
  userID: any;
  navItemList = navItems;
  roleName: any;
  role = Role;
  value_1: string;
  value_2: string;

  constructor(
    

    private _router: Router,
    private _authService: AuthenticationService,
    private _roleService: RoleService,
    private _encryptDecrypt: EncryptDecryptService,
 
  ) {

  }

  ngOnInit() {
    // $('ul').on('expanded.lte.treeview')
    // $(document).ready(() => {
    //   const trees: any = $('[data-widget="tree"]');
    //   trees.tree();
    // });

    this.getRouteListOfUser();
    const data = this._encryptDecrypt.decryptToken();
    this.userID = data.user_id;

    if (this._authService.isLoggedIn && this._authService.isAgent) {
      this.isAgentUser = true
    }

    if (this._authService.isLoggedIn && this._authService.isAdmin) {
      this.isAdminUser = true
    }

    if (this._authService.isLoggedIn && this._authService.isDoctor) {
      this.isDoctorUser = true
    }

    if (this._authService.isLoggedIn && this._authService.isLaboratory) {
      this.isLaboratoryUser = true
    }

  }

  // redirect function of parent menu
  redirectPage(data) {
    if (data === undefined) {
      return;
    } {
      if (data == 'dashboard') {
        this._router.navigate([`dashboard`]);
      }else {
        this._router.navigate([`dashboard/${data}`]);
      }
    }
  }

  // redirect function of child menu
  redirectToPage(router: any) {
    this._router.navigate([`dashboard/${router}`]);
  }

  // clik button to active current menu
  clickButton() {
    $('.nav-item a').click(function (e) {
      $('.nav-item a').removeClass('current_page_item');
      $(this).addClass('current_page_item');
    });

  }


  // get menu list
  isRoleLoading: any = [];
  userMenuListData = []
  userMenu: any = []
  formatedUserMenu: any = []

  getRouteListOfUser() {

    this.isRoleLoading = true;
    this._roleService.getMenuListOfUser(this.userID).subscribe((res) => {
      if (res['status'] == true) {
        this.userMenuListData = res['data']

        for (let data of this.userMenuListData) {
          if (data['parent'] == null) {
            data['parent'] = ""
          }
          this.userMenu.push(data)
        }

        const hierarchize = (parent, list) => {
          const children = list.filter(x => x.parent == parent.id);
          children.forEach(child => hierarchize(child, list));
          parent.hasChild = children;
        }
        this.formatedUserMenu = this.userMenu.filter(x => x.parent == "");
        this.formatedUserMenu.forEach(top => hierarchize(top, this.userMenu));

        if(this._encryptDecrypt.decryptMenuList()==null){
          console.log("Jadd menu list in local storage...")
          this._encryptDecrypt.encryptMenuList(this.userMenuListData )
        }else{
          console.log("Just replace menu list...")
        }


      } {
        this.userMenuListData = []

        if(res['statusCode']=="401" && res['access_status']=="full_expired"){
          this._authService.logout()
          // this._router.navigate(['/'])
          console.log("Ok token expired logout please...............")
        }
      }

    }, error => {
      this.isRoleLoading = false
    }, () => {
      this.isRoleLoading = false
    })
  }

}


