import { Injectable } from '@angular/core';
import { config } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role, RoleRootModel } from '../models/role.interface';

@Injectable({
  providedIn: 'root'
})
export class RoleService {


  constructor(
    private http: HttpClient
  ) { }


  /*-----------------------------------------------------------Role CRUD start ----------------------------------------*/
  // add role
  add_role = `${config.base_url}/role/addrole`
  addRole(bodyObj: any): Observable<Role> {
    return this.http.post<Role>(this.add_role, bodyObj)
  }

  // view role
  view_role = `${config.base_url}/role/role-model`
  viewRole(bodyObj): Observable<any> {
    return this.http.post<any>(this.view_role, bodyObj);
  }

  // edit role
  edit_role = `${config.base_url}/role/editrole`
  editRole(bodyObj): Observable<Role> {
    return this.http.post<Role>(this.edit_role, bodyObj);
  }

  // remove role
  remove_role = `${config.base_url}/role/removerole`
  removeRole(bodyObj): Observable<any> {
    return this.http.post(this.remove_role, bodyObj);
  }

  // get all role list
  get_role_list = `${config.base_url}/role/role-index`
  getAllRolesList(): Observable<RoleRootModel> {
    return this.http.get<RoleRootModel>(this.get_role_list);
  }

  /*-----------------------------------------------------------Role CRUD end ----------------------------------------*/



  /*-----------------------------------------------------------assign/remove route to role start ----------------------------------------*/
  // add role
  assign_route = `${config.base_url}/role/assign-route`
  assignRoute(bodyObj: any): Observable<any> {
    return this.http.post<any>(this.assign_route, bodyObj)
  }

  // remove role
  remove_route = `${config.base_url}/role/remove-route`
  removeRoute(bodyObj: any) {
    return this.http.post<any>(this.remove_route, bodyObj)
  }
  /*-----------------------------------------------------------assign/remove  route to role end ----------------------------------------*/



  // get all route list
  get_route_list = `${config.base_url}/menu/list?role_name=`
  getAllRouteList(roleName:any): Observable<any> {
    // return this.http.get<any>(this.get_route_list);
    return this.http.get<any>(`${this.get_route_list}${roleName}`);
  }

    // get all route list
    get_route_list_role = `${config.base_url}/role/list-route`
    getAllRouteListOfRole(body): Observable<any> {
      return this.http.post<any>(this.get_route_list_role,body);
    }

  /*--------------------------------------------------------------------get menu list of user start--------------------------------------------------*/
  get_route_list_of_user = `${config.base_url}/role/user-menu`
  getMenuListOfUser(getBody){
    return this.http.post<any>(this.get_route_list_of_user,getBody);
  }
  /*--------------------------------------------------------------------get menu list of user end--------------------------------------------------*/

}
