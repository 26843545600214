export const navItems = [
  {
    id: 1,
    displayName: "Dashboard",
    iconName: "nav-icon fas fa-tachometer-alt",
    route: ""
  },

  {
    id: 2,
    displayName: "User",
    iconName: "nav-icon fas fa-users",

    children: [
      {
        displayName: "Manage user",
        iconName: "nav-icon fas fa-user",
        route: "user",
      },
      {
        displayName: "Agent list",
        iconName: "nav-icon fas fa-user-secret",
        route: "agent-user",
      },
    ],
  },
  {
    id: 3,
    displayName: "Test and examination",
    iconName: "nav-icon fa fa-h-square",

    children: [
      {
        displayName: "Test",
        iconName: "nav-icon fas fa-medkit",
        route: "test",
      },
      {
        displayName: "Examination category",
        iconName: "nav-icon fas fa-stethoscope",
        route: "examination-category",
      },
    ],
  },
  {
    id: 4,
    displayName: "Reports",
    iconName: "nav-icon fa fa-file",

    children: [
      {
        displayName: "Super report",
        iconName: "nav-icon fas fa-edit",
        route: "super-report",
      },
      {
        displayName: "Report",
        iconName: "nav-icon fas fa-file-alt",
        route: "report",
      },
    ],
  },
  {
    id: 5,
    displayName: "Services",
    iconName: "nav-icon fas fa-hospital",
    children: [
      {
        displayName: "Service master",
        iconName: "nav-icon fas fa-edit",
        route: "service-master",
      },
      {
        displayName: "Service category",
        iconName: "nav-icon fa fa-wrench",
        route: "service-category",
      },
      {
        displayName: "Service detail",
        iconName: "nav-icon fas fa-file-alt",
        route: "service-detail",
      },
    ],
  },
  {
    id: 6,
    displayName: "Patient",
    iconName: "nav-icon fas fa-head-side-mask",
    children: [
      {
        displayName: "Medical",
        iconName: "nav-icon fas fa-edit",
        route: "medical-patient",
      },
      {
        displayName: "PCR",
        iconName: "nav-icon fas fa-edit",
        route: "pcr-patient",
      },
      {
        displayName: "Orientation",
        iconName: "nav-icon fas fa-file-alt",
        route: "orientation-patient",
      },
    ],
  },
  {
    id: 7,
    displayName: "Package",
    iconName: "nav-icon fas fa-box",
    route: 'package'
  },
  {
    id: 8,
    displayName: "Agency",
    iconName: "nav-icon fas fa-building",
    route: 'agency'
  },
  {
    id: 9,
    displayName: "Commission",
    iconName: "nav-icon fas fa-rupee-sign",
    children: [
      {
        displayName: "Agent commission",
        iconName: "nav-icon fas fa-user-circle",
        route: "agent-commission-list",
      },
      {
        displayName: "Agency commission",
        iconName: "nav-icon fas fa-building",
        route: "agency-commission-list",
      },
    ],
  },
  {
    id: 10,
    displayName: "Rbac",
    iconName: "nav-icon fa fa-edit",
    children: [
      {
        displayName: "Role",
        iconName: "nav-icon fas fa-certificate",
        route: "rbac/role",
      },
      {
        displayName: "Route",
        iconName: "nav-icon fas fa-road",
        route: "rbac/route",
      },
      {
        displayName: "Assignment",
        iconName: "nav-icon fas fa-tasks",
        route: "rbac/assignment",
      },
    ],
  },
  {
    id: 11,
    displayName: "Settings",
    iconName: "nav-icon fas fa-cog",
    route: 'settings'
  },
  {
    id: 12,
    displayName: "Utilities",
    iconName: "nav-icon fa fa-wrench",
    route: ''
  },
]
