import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';
declare var $: any;
import * as $ from 'jquery';
import * as AdminLte from 'admin-lte';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  isAdminUser: boolean = false;
  isAgentUser: boolean = false;
  isDoctorUser: boolean = false;
  isLaboratoryUser: boolean = false;
  userID: string;
  firstName: string;
  firstLetter: string;
  expanded: boolean;
  term: any;
  navListData: any = [];


  constructor(
    private _encryptDecrypt: EncryptDecryptService,
    private _router: Router,

  ) { }

  ngOnInit(): void {
    const data = this._encryptDecrypt.decryptToken();
    this.userID = data.user_id;
    this.firstName = data.first_name;
    this.firstLetter = this.firstName.charAt(0).toUpperCase();
  }


  // return back function
  goBack() {
    this._router.navigate(['dashboard']);
  }


}
